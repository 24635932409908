@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Vollkorn+SC:wght@400;600&display=swap');

/* TODO Move these styles into tailwind.config.js */
body {
	font-family: 'Montserrat', sans-serif;
	/* font-family: 'Lato', sans-serif; */
}
.font-accent {
	font-family: 'Vollkorn SC', serif;
}
.text-10xl {
	font-size: 10rem;
	line-height: 1;
}
.text-11xl {
	font-size: 12rem;
	line-height: 1;
}
.text-shadow {
	text-shadow: 1px 1px 2px #1b1b1b;
}

.backdrop-blur-dark {
	/* TODO Add Rucksack lib */
	/* background-color: rgba(#000000, 0.8); */
	background-color: rgba(0, 0, 0, 0.8);
}
.backdrop-blur-light {
	background-color: rgba(255, 255, 255, 0.8);
	/* background-color: rgba(#ffffff, 0.8); */
}
@supports (backdrop-filter: blur(12px)) {
	.backdrop-blur-dark {
		/* background-color: rgba(#000000, 0.2); */
		background-color: rgba(0, 0, 0, 0.3);
		backdrop-filter: blur(12px);
	}
	.backdrop-blur-light {
		/* background-color: rgba(#ffffff, 0.1); */
		background-color: rgba(255, 255, 255, 0.1);
		backdrop-filter: blur(12px);
	}
}
